import Palette from './Palette'

export default {
  primary: Palette.primary.self,
  secondary: '#ab003c',
  secondaryLight: Palette.primary.accent[2],
  primaryLight: Palette.primary.lighten[1],
  primaryDark: Palette.primary.darken[1],

  textPrimary: Palette.grey.darken[5],
  textSecondary: Palette.grey.self,

  borderGray: Palette.grey.lighten[1],
  lighterGray: Palette.grey.lighten[3],
  lightgrey: Palette.grey.lighten[2],
  darkgrey: Palette.grey.darken[1],
  darkSecondary: Palette.grey.darken[4],
  darkgreen: 'rgb(0, 105, 95)',
  greencreate: '#4caf50',
  greencreatehover: '#5eaf6b',
  white: Palette.white.self,
  black: Palette.black.self,
  text: Palette.grey.darken[4],
  transparent: Palette.transparent.self,
  laragrey: Palette.grey.darken[3],
  fallbackGrey: Palette.blue_grey.lighten[5],

  help: Palette.orange.darken[1],
  helpTransparent: Palette.orange.lighten[5],
  helpGray: Palette.grey.lighten[1],
  lightYellow: Palette.yellow.lighten[5],
  lightYellowSub: Palette.yellow.lighten[4],
  lightYellow3: '#fffff0',
  chipGray: Palette.grey.darken[1],

  /* Table Rows */
  tableEven: Palette.grey.lighten[5],
  tableRowWhite: Palette.grey.lighten[5],
  tableRowWhiteDarken: Palette.grey.lighten[4],
  tableRowGrey: Palette.grey.lighten[5],
  tableRowGreyDarken: Palette.blue_grey.lighten[5],

  /*Selected Rows*/
  lightGreen: Palette.green.lighten[5],
  lightGreenSub: Palette.green.lighten[4],

  authBackground: Palette.grey.darken[1],

  /* Chat colors VIC */
  orange: Palette.orange.self,
  llGray: Palette.grey.lighten[3],
  green2: Palette.light_green.lighten[1],
  green3: Palette.light_green.self,
  lGreen: Palette.light_green.lighten[3],
  llGreen: Palette.light_green.lighten[4],
  blue3: Palette.blue.self,

  paletteMartin: [
    '#c78383',
    '#b089c9',
    '#c783ac',
    '#5ebbd2',
    '#5e7dd2',
    '#89adc9',
    '#b2c989',
    '#c9ab89',
  ],

  paletteCharts: [
    '#89adc9',
    '#4ea899',
    '#63cc91',
    '#c1cc6f',
    '#cfb066',
    '#cece45',
    '#4e8ca8',
    '#4e9ca8',
  ],

  card_single: '#dcdcdc',

  dtr_normal: Palette.primary.self,
  dtr_moderated: Palette.ambar.lighten[1],
  dtr_intense: Palette.red.accent[6],

  /* Buttons */
  danger: Palette.red.lighten[2],
  warning: Palette.ambar.lighten[1],
  darkWarning: Palette.ambar.darken[1],
  warningLight: Palette.ambar.lighten[1],
  success: Palette.primary.self,
  error: Palette.red.lighten[1],
  info: Palette.cyan.accent[4],
  muted: Palette.grey.darken[1],

  /* Ai recommended */
  ai_recommended: Palette.blue.accent[4],

  /* Heat Strength */
  heat_empty: Palette.grey.lighten[3],
  heat_weak: Palette.ambar.lighten[1],
  heat_normal: Palette.ambar.accent[3],
  heat_strong: Palette.ambar.darken[2],

  /* Farm contract badges */
  contract_badge_trial: Palette.light_green.accent[4],
  contract_badge_active: Palette.green.darken[3],
  contract_badge_standby: Palette.ambar.self,
  contract_badge_suspended: Palette.deep_orange.lighten[1],
  contract_badge_inactive: Palette.red.self,
  contract_badge_expired: Palette.grey.self,

  /* Reproduction events colors */
  reproduction_discard: Palette.red.lighten[2],
  reproduction_abortion: '#ff5354',
  reproduction_birth: Palette.cyan.accent[4],
  reproduction_calf_new_born: Palette.pink.lighten[4],
  reproduction_calf: Palette.pink.accent[1],
  reproduction_heifer_na: Palette.pink.accent[3],
  reproduction_heifer: Palette.pink.accent[6],
  reproduction_dry: Palette.deep_blue.lighten[1], 
  reproduction_embryo_transfer: '#83cc8c',
  reproduction_empty: Palette.green_grey.darken[1], 
  reproduction_empty_post_delivery: Palette.ambar.self,
  reproduction_heat: Palette.cyan.accent[4],
  reproduction_visual_heat: Palette.cyan.lighten[3],
  reproduction_natural_breeding: Palette.deep_orange.lighten[2],
  reproduction_heat_negatively_confirmed: '#c3c3c3',
  reproduction_hormone_implanted: Palette.red.accent[2],
  reproduction_implant_insertion: Palette.red.accent[2],
  reproduction_implant_lost: '#ce6c5e',
  reproduction_implant_removal: Palette.red.accent[3],
  reproduction_implant_removed: Palette.red.accent[3],
  reproduction_induced_lactation: '#41a781',
  reproduction_inseminated: Palette.green.lighten[1],
  reproduction_late_inseminated: Palette.brown.accent[1],
  reproduction_negative_pregnancy: '#b8abbf',
  reproduction_male: Palette.grey.darken[4],
  reproduction_pregnant: Palette.purple.lighten[3],
  reproduction_pregnant_pre_delivery: Palette.pink.accent[5],
  reproduction_prostaglandin: Palette.deep_orange.lighten[1],
  reproduction_pregnant_second_confirmation: Palette.purple.lighten[3],
  reproduction_pregnant_third_confirmation: Palette.purple.lighten[3],
  reproduction_apt_after_delivery: Palette.light_green.self,
  reproduction_inapt_after_delivery: Palette.yellow.darken[2],
  reproduction_weaning: Palette.cyan.accent[4],

  /* Cowanalitycs Dim on First Service Graph */
  dimOnFirstService_pregnant: Palette.green.self,
  dimOnFirstService_not_pregnant: Palette.red.lighten[2],
  dimOnFirstService_awaiting_confirmation: Palette.grey.lighten[1],

  /* Heat index */
  final_index: Palette.deep_orange.lighten[5],
  final_index_accumulated: Palette.deep_orange.lighten[2],
  final_index_accumulated_hours: Palette.deep_orange.accent[1],
  limit_one_hour: Palette.grey.darken[5],
  limit_two_hours: Palette.orange.lighten[2],
  limit_n_hours: Palette.primary.lighten[1],
  heat_index_1: Palette.yellow.darken[1],
  heat_index_2: Palette.yellow.darken[4],
  heat_index_3: Palette.orange.darken[4],

  /* Health events colors */
  health_condition: Palette.ambar.self,
  health_condition2: '#c8ac3f88',
  health_condition_confirmation: '#ca8336',

  /* Handling events colors */
  handling_general: Palette.deep_blue.self,
  handling_nutrition: Palette.deep_blue.self,
  handling_reproduction: Palette.deep_blue.self,
  handling_sanitary: Palette.deep_blue.self,

  rumination_threshold_danger: Palette.green.darken[2],
  rumination_variation_increase: Palette.green.darken[2],
  rumination_variation_decrease: Palette.green.darken[2],

  /* Diet Handling Colors */
  diet_handling: Palette.green.self,
  diet: Palette.green.darken[2],

  /* Milk control */
  milk_quality: Palette.cyan.accent[4],

  /* THI plotBands colors */
  thermal_comfort: 'transparent',
  stress_zone: Palette.orange.lighten[4],
  mild_to_moderate_stress: Palette.deep_orange.lighten[4],
  moderate_to_severe_stress: Palette.red.accent[1],
  severe_stress: Palette.red.lighten[3],
  intake: Palette.lime.darken[3],

  normal: '#29841a',
  challenge: Palette.ambar.darken[2],
  critical: Palette.red.accent[5],
  observation: '#D85C13',
  recovered: Palette.green.accent[4],
  recovering: Palette.green.lighten[1],
  aggravating: Palette.red.lighten[1],
  breathing: Palette.orange.lighten[1],

  /* Status Collar */
  collarBadge: Palette.cyan.accent[4],
  collarCriticalError: Palette.deep_orange.darken[2],
  collarMinorError: Palette.ambar.self,
  collarInconsistent: Palette.purple.lighten[2],

  inductionBadge: Palette.deep_purple.lighten[3],
  moderationBadge: Palette.ambar.darken[3],
  confirmationBadge: Palette.brown.accent[1],
  confirmationBadgeHover: Palette.transparent[1],
  moderationLineBackground: '#ffcab6',
  confirmationLineBackground: '#fdf5e2',
  notificationBadge: Palette.deep_orange.self,

  colorRumination: Palette.green.accent[1],
  colorActivity: Palette.deep_orange.lighten[2],
  colorLeisure: Palette.deep_blue.self,
  colorMilkProduction: Palette.blue.lighten[3],
  colorTrend: Palette.grey.darken[5],

  // modules colors
  view_cattle: Palette.brown.darken[1],
  view_cattle_hover: '#a49075',

  view_reproduction: '#C64471',
  view_reproduction_hover: '#AE3F5F',

  view_health: Palette.red.lighten[3],
  view_health_hover: '#b45c5f',

  view_ctech: Palette.grey.darken[3],
  view_ctech_hover: '#454242',

  view_precision_nutrition: '#9fb467',
  view_precision_nutrition_hover: '#8ba059',

  view_milk_control: '#6398ab',
  view_milk_control_hover: '#558598',

  view_environmental_comfort: '#dc8766',
  view_environmental_comfort_hover: '#b47055',

  view_cattle_handling: '#45536f',
  view_cattle_handling_hover: '#384661',

  view_report: '#56C1AE',
  view_report_hover: '#4aa794',

  view_protocol: '#cc5f61',
  view_protocol_hover: '#a55a5a',

  view_animal_individualized: 'rgba(222, 195, 88, 0.96)',
  view_animal_individualized_hover: 'rgba(213, 185, 86, 0.96)',

  view_animal_group: 'rgba(222, 176, 88, 0.96)',
  view_animal_group_hover: 'rgba(190,145,83,0.96)',

  // alerts colors
  heat_alert: Palette.deep_purple.lighten[2],
  health_observation_alert: Palette.deep_orange.muted[1],
  health_critical_alert: Palette.red.accent[5],
  nutrition_alert: Palette.primary.self,
  delivery_alert: Palette.pink.accent[4],
  milk_return: Palette.light_blue.accent[3],

  // milk production analysis chart
  scc: Palette.orange.lighten[1],
  spc: Palette.orange.lighten[3],
  protein: Palette.primary.muted[6],
  fat: Palette.light_green.lighten[3],
  solid: Palette.green.lighten[3],
  urea: Palette.teal.lighten[3],
  lactose: Palette.blue_grey.lighten[3],
  snf: Palette.primary.muted[2],

  // protocol status
  protocol_programmed: Palette.grey.lighten[1],
  protocol_awaiting: Palette.yellow.darken[2],
  protocol_implanted: Palette.yellow.darken[1],
  protocol_completed: Palette.primary.self,

  // collar stock indicators
  collar_available: Palette.primary.self,
  collar_discard: Palette.red.lighten[2],
  collar_revision: Palette.yellow.darken[2],
  collar_cotriba: Palette.lime.darken[1],
  collar_cotrijal: Palette.green.self,

  // chart default colors
  expected_rate: Palette.orange.self,
  heifer_rate: Palette.cyan.lighten[3],
  primiparous_rate: Palette.cyan.darken[1],
  multiparous_rate: Palette.deep_blue.darken[1],
  adult: Palette.blue.lighten[3],

  /* Discard */
  involuntary_discard: Palette.red.lighten[1],
  voluntary_discard: Palette.red.lighten[2],
  sold_discard: Palette.deep_orange.lighten[2],
  lactations_discard: Palette.deep_orange.lighten[3],
  animal_discard_icon: Palette.grey.lighten[1],
  animal_discard_text: Palette.grey.darken[2],

  // hover colors are the same color but subtracted 0x111111 from the original ones
  hover: {
    danger: Palette.red.lighten[1],
    info: '#3194e4',
    moderationBadge: Palette.ambar.darken[4],
    muted: '#737373',
    primary: Palette.primary.darken[1],
    success: '#6bab7e',
    warning: Palette.ambar.self,
  },

  conception_del: [
    Palette.purple.lighten[3],
    Palette.indigo.lighten[2],
    Palette.primary.darken[1],
    Palette.primary.darken[1],
    Palette.primary.darken[1],
    Palette.red.lighten[2],
    Palette.red.lighten[2],
    Palette.red.lighten[2],
    Palette.red.lighten[2],
    Palette.red.lighten[2],
    Palette.red.lighten[2],
    Palette.red.lighten[2],
    Palette.red.lighten[2],
  ],
  alerts_new: Palette.grey.lighten[4],

  // PPI
  ppi_normal: Palette.primary.self,
  ppi_challenge: Palette.ambar.self,
}
